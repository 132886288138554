import revive_payload_client_maXFCe6R9G from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_lcwttm5hp2x5l52kluuafw3lcu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fScrk6v5bO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_lcwttm5hp2x5l52kluuafw3lcu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mUyX5IiRFl from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_lcwttm5hp2x5l52kluuafw3lcu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_sPYe3t2aIO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_lcwttm5hp2x5l52kluuafw3lcu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_b8NgukqP5P from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_lcwttm5hp2x5l52kluuafw3lcu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_p0pVlHqWEG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_lcwttm5hp2x5l52kluuafw3lcu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NaQ0qhwT4u from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_lcwttm5hp2x5l52kluuafw3lcu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_MPfBBt6Psm from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.1.0_ioredis@5.4.1_magicast@0.3.4_rollup@4.20_lcwttm5hp2x5l52kluuafw3lcu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_maXFCe6R9G,
  unhead_fScrk6v5bO,
  router_mUyX5IiRFl,
  payload_client_sPYe3t2aIO,
  navigation_repaint_client_b8NgukqP5P,
  check_outdated_build_client_p0pVlHqWEG,
  chunk_reload_client_NaQ0qhwT4u,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MPfBBt6Psm
]